import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import DaysFilter from "./DaysFilter";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CommonLoader from "../../../../loader/CommonLoader";
import { IoIosHelpCircleOutline } from "react-icons/io";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { LoginContext } from "../../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokFollowersPerDay = () => {
  const [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    { theme } = useContext(LoginContext),
    [followerData, setFollowerData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchFollowerData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    try {
      const response = await axiosInstance.post(
        "/api/dashboard/follower-count-per-day",
        {
          days: null,
          from,
          to,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setFollowerData(response.data.data);

      if (!response.data.data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }

      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch follower count data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFollowerData();
  }, [selectedDays]);

  const generateChartData = () => {
    const labels = followerData.map((item) =>
      moment(item.date).format("DD MMM, YY")
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Followers Per Day",
          data: followerData.map((item) => item.total_followers),
          borderColor: "#36A2EB",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          pointRadius: 0,
          fill: true,
          tension: 0.4,
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className="follower-growth-container">
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchFollowerData} />
      ) : (
        <div className="chart-wrapper">
          {followerData.length > 0 ? (
            <FullscreenWrapper>
              <Line data={generateChartData()} options={options} />
            </FullscreenWrapper>
          ) : (
            <p>No follower data available for the selected period.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TikTokFollowersPerDay;
