import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import YearFilter from "./YearFilter";
import ErrorPage from "../../common-components/error";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { backgroundColors, colors } from "../../../../utils/constants";
import { LoginContext } from "../../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const InteractionsAvgLineMetrix = () => {
  const [selectedYear, setYear] = useState(2024),
    { theme } = useContext(LoginContext),
    [interactionsAvgData, setInteractionsAvgData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchInteractionsAvg = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        "api/tiktok/interactions-average",
        {
          year: selectedYear,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInteractionsAvgData(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch interactions average data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInteractionsAvg();
  }, [selectedYear]);

  const generateChartData = () => {
    const labels = interactionsAvgData.map(
      (item) => `${item.month}-${item.year}`
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Interactions Avg",
          data: interactionsAvgData.map((item) => item.average_interactions),
          borderColor: colors.light_yellow,
          backgroundColor: backgroundColors.lighter_yellow,
          fill: true,
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Month-Year",
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Average Interactions",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="interactions-avg-line-graph">
      <YearFilter selectedYear={selectedYear} onChange={setYear} />
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchInteractionsAvg} />
      ) : (
        <div className="chart-wrapper">
          {interactionsAvgData.length > 0 ? (
            <FullscreenWrapper>
              <Line data={generateChartData()} options={options} />
            </FullscreenWrapper>
          ) : (
            <p>No data available for the selected year.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default InteractionsAvgLineMetrix;
