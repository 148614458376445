import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import subDays from "date-fns/subDays";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TikTokStats = () => {
  const [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [clientData, setClientData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchTikTokStats = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    try {
      const response = await axiosInstance.post(
        "/api/dashboard/client-tiktok-stats",
        { days: null, from, to },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientData(response.data.data);
      if (!response.data.data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setError(null);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch TikTok stats data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTokStats();
  }, [selectedDays]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: { position: "top" },
      title: {
        display: false,
      },
    },
  };
  const generateChartData = (client) => {
    const labels = client.tiktok_accounts.map(
      (account) => account.tiktok_nickname
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Followers",
          data: client.tiktok_accounts.map((account) => account.follower_count),
          backgroundColor: "#36A2EB",
        },
        {
          label: "Following",
          data: client.tiktok_accounts.map(
            (account) => account.following_count
          ),
          backgroundColor: "#FFCE56",
        },
        {
          label: "Total Likes",
          data: client.tiktok_accounts.map((account) => account.total_likes),
          backgroundColor: "#4BC0C0",
        },
        {
          label: "Total Videos",
          data: client.tiktok_accounts.map((account) => account.total_videos),
          backgroundColor: "#FF6384",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DaysFilter selectedDays={selectedDays} onChange={onChange} />

        {loading ? (
          <div className="loader-overlay">
            <CommonLoader />
          </div>
        ) : noData ? (
          <NoDataFoundPage />
        ) : error ? (
          <ErrorPage refetch={fetchTikTokStats} />
        ) : (
          clientData.map((client) => (
            <div key={client.client_id} className="client-chart">
              {client.tiktok_accounts.length > 0 ? (
                <>
                  <h5 className="chart-title">
                    TikTok Stats for ${client.client_name} &nbsp;
                    <span
                      data-tooltip-id="help-tooltip"
                      style={{ fontSize: "26px" }}
                    >
                      <IoIosHelpCircleOutline />
                    </span>
                    <ReactTooltip
                      id="help-tooltip"
                      place="bottom"
                      content="Helping Text"
                    />
                  </h5>
                  <FullscreenWrapper>
                    <Bar data={generateChartData(client)} options={options} />
                  </FullscreenWrapper>
                </>
              ) : (
                <p>No TikTok accounts available for this client.</p>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TikTokStats;
