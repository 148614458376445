import React, { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import YearFilter from "./YearFilter";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { backgroundColors, colors } from "../../../../utils/constants";
import { LoginContext } from "../../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EffectivenessMetrix = () => {
  const [selectedYear, setYear] = useState(2024),
    { theme } = useContext(LoginContext),
    [effectivenessData, setEffectivenessData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchEffectiveness = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        "/api/tiktok/effectiveness-percentage",
        {
          year: selectedYear,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEffectivenessData(response.data.data);

      if (!response.data.data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }

      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch effectiveness data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEffectiveness();
  }, [selectedYear]);

  const generateChartData = () => {
    const labels = effectivenessData.map(
      (item) => `${item.month}-${item.year}`
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Engagement %",
          data: effectivenessData.map((item) => item.effectiveness_percentage),
          backgroundColor: colors.pink,
          borderColor: backgroundColors.light_pink,
          borderWidth: 1,
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Month: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Month-Year",
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Engagement %",
        },
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="effectiveness-bar-graph">
      <YearFilter selectedYear={selectedYear} onChange={setYear} />
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchEffectiveness} />
      ) : (
        <div className="chart-wrapper">
          {effectivenessData.length > 0 ? (
            <FullscreenWrapper>
              <Bar data={generateChartData()} options={options} />
            </FullscreenWrapper>
          ) : (
            <p>No data available for the selected year.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EffectivenessMetrix;
