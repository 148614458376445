import React from "react";
import Card from "react-bootstrap/Card";

function CustomCard({ style, header, children }) {
  return (
    <Card className="bg-chart" style={{ height: style }}>
      {header && <Card.Header className="pd-2">{header}</Card.Header>}
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default CustomCard;
