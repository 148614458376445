import { IoChevronBack } from "react-icons/io5";

export const returnBackButton = (size = 2) => {
  return (
    <IoChevronBack
      style={{ fontSize: `${size}rem`, cursor: "pointer" }}
      onClick={() => window.history.back()}
    />
  );
};

export function getMaxHeightByIds(id1, id2) {
  const element1 = document.getElementById(id1);
  const element2 = document.getElementById(id2);

  const height1 = element1 ? element1.getBoundingClientRect().height : 0;
  const height2 = element2 ? element2.getBoundingClientRect().height : 0;

  return Math.max(height1, height2);
}
