import React, { useEffect, useState } from "react";
import { MdAccountBox } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
import { SiGoogledocs } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { digitsColor } from "../../../../utils/constants";
import ClickableCards from "../ClickableCards";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";

const MainCards = () => {
  const [userDetails, setUserDetails] = useState({
      tiktokAccountCount: 0,
      tiktokVideoCount: 0,
      tiktokSoundCount: 0,
      dailyApiCount: 0,
    }),
    [isLoading, setIsLoading] = useState(false),
    role = localStorage.getItem("userRole"),
    isSuperAdmin = role === "super-admin";

  const getCounts = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/dashboard/tiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const {
          tiktokAccountCount,
          tiktokVideoCount,
          tiktokSoundCount,
          dailyApiCount,
        } = response.data.stats;
        setUserDetails({
          tiktokAccountCount,
          tiktokVideoCount,
          tiktokSoundCount,
          dailyApiCount,
        });
      }
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className="metrix">
      <ClickableCards
        cards={[
          {
            icon: <MdAccountBox size={40} color={digitsColor.blue} />,
            title: "Tiktok Accounts",
            amount: userDetails?.tiktokAccountCount,
            link: "/tiktokAccounts",
          },
          {
            icon: <GrStatusGood size={40} color={digitsColor.green} />,
            title: "Tiktok Posts",
            amount: userDetails?.tiktokVideoCount,
            link: "/tiktokVideos",
          },
          {
            icon: <SiGoogledocs size={40} color={digitsColor.purple} />,
            title: "Tiktok Sounds",
            amount: userDetails?.tiktokSoundCount,
            link: "/tiktok-sounds",
          },
          isSuperAdmin
            ? {
                icon: <TbApi size={40} color={digitsColor.red} />,
                title: "Daily API Hits",
                amount: userDetails?.dailyApiCount,
                link: "/dailyApiHits",
              }
            : {},
        ]}
      />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default MainCards;
