import React, { useState, useEffect, useRef, useContext } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import TikTokStats from "../pages/component/TikTokStats";
import UserCount from "../pages/component/UserCount";
import TopTrendingVideos from "./component/TopTrendingVideos";
import TikTokFollowerGrowth from "./component/TikTokFollowerGrowth";
import TikTokEngagementMetrics from "./component/TikTokEngagementMetrics";
import TikTokHashtagPerformance from "./component/TikTokHashtagPerformance";
import TikTokIndividualHashtagPerformance from "./component/TikTokIndividualHashtagPerformance";
import TotalVideosMetrix from "./component/TotalVideosMetrix";
import EffectivenessMetrix from "./component/EffectivenessMetrix";
import ViewsAvgLineMetrix from "./component/ViewsAvgLineMetrix";
import InteractionsAvgLineMetrix from "./component/InteractionsAvgLineMetrix";
import { digitsColor, roles } from "../../../utils/constants";
import TikTokSoundStatistics from "./component/TikTokSoundStatistics";
import TikTokFollowersPerDay from "./component/TikTokFollowersPerDay";
import CustomCard from "../common-components/cardWrapper";
import MainCards from "../common-components/main-cards";
import { getMaxHeightByIds } from "../../../utils/helperfunctions";

const DashboardIndex = () => {
  const role = localStorage.getItem("userRole"),
    hasRole = roles.includes(role),
    hasAdminRole = role === "super-admin" || role === "admin",
    hasClientRole = role === "client",
    [heights, setHeights] = useState({
      firstRow: "",
      secondRow: "",
      thirdRow: "",
      fourthRow: "",
      fivthRow: "",
    });

  useEffect(() => {
    setTimeout(() => {
      const firstRow = getMaxHeightByIds("trending-videos", "user-count");
      const secondRow = getMaxHeightByIds(
        "follower-per-day",
        "hashtag-performance"
      );
      const thirdRow = getMaxHeightByIds(
        "individual-hashtag-performance",
        "video-per-month"
      );
      const fourthRow = getMaxHeightByIds("engagement-percentage", "views-avg");
      const fivthRow = getMaxHeightByIds("sound-stats", "interaction-avg");

      setHeights({
        firstRow,
        secondRow,
        thirdRow,
        fourthRow,
        fivthRow,
      });
    }, 5000);
  }, []);

  return (
    <div className="userLists">
      <MainCards />

      <CommonHeader dashboardName={"Dashboard"} />

      {hasRole && (
        <>
          {/* First Row */}
          <div className="row mb-4 mt-4">
            <div className="col-md-4" id="trending-videos">
              <CustomCard
                header={"Top Trending Videos"}
                style={heights?.firstRow}
              >
                <TopTrendingVideos />
              </CustomCard>
            </div>

            <div className="col-md-8" id="user-count">
              <CustomCard
                header={"Total Users and TikTok Accounts"}
                style={heights?.firstRow}
              >
                <UserCount />
              </CustomCard>
            </div>
          </div>

          {/* Second Row */}
          <div className="row mb-4">
            <div className="col-md-6" id="follower-per-day">
              <CustomCard
                header={"Followers Per Day"}
                style={heights?.secondRow}
              >
                <TikTokFollowersPerDay />
              </CustomCard>
            </div>

            <div className="col-md-6" id="hashtag-performance">
              <CustomCard
                header={"TikTok Hashtag Performance"}
                style={heights?.secondRow}
              >
                <TikTokHashtagPerformance />
              </CustomCard>
            </div>
          </div>
          {/* 3rd Row */}
          <div className="row mb-4">
            <div className="col-md-6" id="individual-hashtag-performance">
              <CustomCard
                header={"TikTok Individual Hashtag Performance"}
                style={heights?.thirdRow}
              >
                <TikTokIndividualHashtagPerformance />
              </CustomCard>
            </div>

            <div className="col-md-6" id="video-per-month">
              <CustomCard
                header={"Total Videos Uploaded per Month"}
                style={heights?.thirdRow}
              >
                <TotalVideosMetrix />
              </CustomCard>
            </div>
          </div>
          {/* 4th Row */}
          <div className="row mb-4">
            <div className="col-md-6" id="engagement-percentage">
              <CustomCard
                header={"Engagement Percentage"}
                style={heights?.fourthRow}
              >
                <EffectivenessMetrix />
              </CustomCard>
            </div>

            <div className="col-md-6" id="views-avg">
              <CustomCard header={"Views Average"} style={heights?.fourthRow}>
                <ViewsAvgLineMetrix />
              </CustomCard>
            </div>
          </div>
          {/* 5th Row */}
          <div className="row mb-4">
            <div className="col-md-6" id="sound-stats">
              <CustomCard
                header={"TikTok Sound Statistics"}
                style={heights?.fivthRow}
              >
                <TikTokSoundStatistics />
              </CustomCard>
            </div>
            <div className="col-md-6" id="interaction-avg">
              <CustomCard
                header={"Interactions Average"}
                style={heights?.fivthRow}
              >
                <InteractionsAvgLineMetrix />
              </CustomCard>
            </div>
          </div>
        </>
      )}

      <div className="row mb-4">
        {hasClientRole && (
          <CustomCard header={""}>
            <div className="col-md-6">
              <TikTokStats />
            </div>
          </CustomCard>
        )}
      </div>

      <div className="row mb-4">
        {hasAdminRole && (
          <>
            <div className="col-md-6">
              <CustomCard header={""}>
                <TikTokFollowerGrowth />
              </CustomCard>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardIndex;
