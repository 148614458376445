import React, { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import subDays from "date-fns/subDays";
import ErrorPage from "../../common-components/error";
import LimitFilter from "./LimitFilter";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../../../App";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { colors } from "../../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const abbrivate = (text) => {
  if (!text) return "";
  return text.length > 15 ? `${text.substring(0, 15)}` : text;
};

const TikTokSoundStatistics = () => {
  const { selectedDays, setSelectedDays, theme } = useContext(LoginContext),
    [selectedLimit, setSelectedLimit] = useState(10),
    [response, setResponse] = useState([]),
    [labels, setLabels] = useState([]),
    [values, setValues] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    navigate = useNavigate(),
    [noData, setNoData] = useState(null);

  const fetchTikTokSoundStats = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    try {
      const response = await axiosInstance.post(
        "/api/dashboard/sound-performance-metrics",
        { days: null, from, to, limit: selectedLimit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const localLabel = [];
      const localValues = [];

      const stats = response.data.data;

      stats?.forEach((data) => {
        localLabel.push(data.sound_id);
        localValues.push(data.total_users);
      });

      setLabels(localLabel);
      setValues(localValues);
      setResponse(response.data.data);
      if (!response.data.data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setError(null);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch TikTok stats data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTokSoundStats();
  }, [selectedDays, selectedLimit]);

  const generateChartData = () => {
    const data = {
      labels: labels.map((label) => {
        const truncatedStr = `${label.slice(0, 2)}...${label.slice(-2)}`;
        return truncatedStr;
      }),
      datasets: [
        {
          label: "Total Videos",
          data: values || [],
          backgroundColor: colors.yellow,
        },
      ],
    };
    return data;
  };

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: function (context) {
            const fullSoundId = labels[context.dataIndex];
            const value = context.raw;
            return `${fullSoundId}: ${value} users`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          autoSkip: false,
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: "Sound's",
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
    onClick: (event, elements) => handleChartClick(event, elements),
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };
  const onChangeLimit = (value) => {
    setSelectedLimit(value);
  };
  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const foundRecord = response.filter(
        (record) => labels[index] === record.sound_id
      );

      navigate(`/Tiktok-sound-statistics/${foundRecord[0]?.sound_id}`);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="filter-container p-3">
          <div className="row">
            <div className="col-md-6">
              <DaysFilter selectedDays={selectedDays} onChange={onChange} />
            </div>
            <div className="col-md-6 limit-filter">
              <LimitFilter
                selectedLimit={selectedLimit}
                onChange={onChangeLimit}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-overlay">
            <CommonLoader />
          </div>
        ) : noData ? (
          <NoDataFoundPage />
        ) : error ? (
          <ErrorPage refetch={fetchTikTokSoundStats} />
        ) : (
          <div className="chart-wrapper">
            {values?.length > 0 ? (
              <FullscreenWrapper>
                <Bar data={generateChartData()} options={chartOptions} />
              </FullscreenWrapper>
            ) : (
              <p>No Statistics found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TikTokSoundStatistics;
