import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alert from "react-bootstrap/Alert";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { ImAttachment } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";

const FreelancersListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [freelancers, setFreelancers] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [editedStatus, setEditedStatus] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [statusOptions, setStatusOption] = useState([]),
    [selectedOptions, setSelectedOptions] = useState([]),
    [newFreelancer, setNewFreelancer] = useState({
      name: "",
      phone: "",
      address: "",
      email: "",
      password: "",
    }),
    [type, setType] = useState({
      visible: false,
      data: [],
    }),
    [show, setShow] = useState(false),
    [deleteFreelancer, setDeleteFreelancer] = useState(false),
    [selectedFreelancer, setSelectedFreelancer] = useState(""),
    [options, setOptions] = useState({
      clients: [],
    });

  const handleClose = () => {
    setShow(false);
    setNewFreelancer({
      name: "",
      phone: "",
      address: "",
      email: "",
      password: "",
    });
    setEditedStatus("");
    setIsEditing("");
    setSelectedFreelancer("");
    setSelectedOptions([]);
    setType({
      visible: false,
      data: [],
    });
  };
  const navigate = useNavigate();

  const fetchFreelancers = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/freelancers?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFreelancers(response.data.data);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching freelancers:", error);
    }
    setIsLoading(false);
  };
  const getClientOptions = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/dashboard/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // const data = response.data.clients;
        const data = response.data.clients.map((client) => ({
          label: client.name,
          value: client.name,
          id: client.id,
        }));

        setOptions({
          clients: data,
        });
      }
    } catch (error) {
      console.error("Error fetching freelancers:", error);
    }
    setIsLoading(false);
  };
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    fetchFreelancers(currentPage);
    getClientOptions();
    handleShow();
    const intervalId = setInterval(() => {
      fetchFreelancers();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handleEdit = async (record) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/api/freelancers/${record.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);

        setShow(true);
        const {
          name,
          phone,
          address,
          user: { email },
        } = response.data.freelancer;

        setIsEditing(response.data.freelancer);

        setNewFreelancer((prevState) => ({
          ...prevState,
          name,
          phone,
          address,
          email,
        }));
        setEditedStatus(response.data.freelancer.status);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching freelancer for edit:", error);
    }
  };

  const handleShow = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/freelancers/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const createOptions = Object.values(response?.data[`status-dropdown`]);
        const localOptions = [
          { id: 0, name: createOptions[0] },
          { id: 1, name: createOptions[1] },
        ];

        setStatusOption(localOptions);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating freelancer");
      }
    }
  };

  const handleDelete = async (freelancerId) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/freelancers/${freelancerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFreelancers(
        freelancers.filter((freelancer) => freelancer.id !== freelancerId)
      );
      toast.success("Freelancer deleted successfully!");
      setDeleteFreelancer(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else {
        console.error("Error deleting freelancer:", error);
        toast.error("Error deleting freelancer");
      }
    }
    setIsLoading(false);
  };

  const handleUpdateFreelancer = async () => {
    const token = localStorage.getItem("token");

    const updatedFreelancerData = {
      name: newFreelancer.name,
      phone: Number(newFreelancer.phone),
      address: newFreelancer.address,
      email: newFreelancer.email || "", // Access email from the user object
      status: Number(editedStatus),
      user_id: isEditing.user?.id || null,
    };

    try {
      const response = await axiosInstance.put(
        `/api/freelancers/${isEditing.id}`,
        updatedFreelancerData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Freelancer updated successfully");
      fetchFreelancers();
      if (response.status === 201) {
        setIsEditing("");
      } else {
        console.error("Error updating freelancer:", response);
      }
    } catch (error) {
      console.error("Error updating freelancer:", error);
      toast.error("Please try again");
    }
  };

  const handleCreateFreelancer = async () => {
    const { name, phone, address, email, password } = newFreelancer;

    // Validate the form fields
    if (!name || !phone || !address || !email || !password) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const freelancerPayload = {
      name,
      phone: Number(phone),
      address,
      email,
      password,
    };

    try {
      await axiosInstance.post(`/api/freelancers`, freelancerPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      fetchFreelancers();
      setNewFreelancer({
        name: "",
        phone: "",
        address: "",
        email: "",
        password: "",
      });
      toast.success("Freelancer created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating freelancer");
      console.error("Error creating freelancer:", error);
    }
  };

  const getAttachedClients = async (row) => {
    setSelectedFreelancer(row);
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/freelancers/${row?.id}/clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { clients } = response?.data;
      if (response.status === 200) {
        const data = clients;
        setType({
          visible: true,
          data,
        });
        const activeRecord = data.map((r) => {
          if (r.status === "Active") {
            return {
              id: r.id,
              label: r.name,
              value: r.name,
            };
          }
        });

        setSelectedOptions(activeRecord);

        setShow(true);
      }
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handleAttachment = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const list = selectedOptions.map((selected) => selected.id);
    const activeIds = type.data
      .filter((record) => record.status === "Active" && record.id)
      .map((r) => r.id);

    let payload = {
      freelancer_id: selectedFreelancer?.id,
      clients: [...activeIds, ...list],
    };

    try {
      await axiosInstance.post(`/api/freelancers/attach-clients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      fetchFreelancers();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const filteredFreelancers = freelancers.filter((freelancer) =>
    freelancer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Email</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Attach Clients</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredFreelancers?.length > 0 ? (
            filteredFreelancers?.map((freelancer, index) => (
              <tr key={freelancer.id}>
                <td>{index + 1}</td>
                <td>{freelancer.name}</td>
                <td>{freelancer.phone}</td>
                <td>{freelancer.address}</td>
                <td>{freelancer.email}</td>
                <td>{freelancer.created_by}</td>
                <td>{freelancer.created_at}</td>
                <td>
                  <Toaster position="top-center" richColors />
                  <button
                    data-tooltip-id="attach-client"
                    className="edit me-2"
                    onClick={() => getAttachedClients(freelancer)}
                    style={{
                      padding: "4px 10px",
                      fontSize: "22px",
                    }}
                    disabled={!hasPermission(user?.permissions, "view-client")}
                  >
                    <ImAttachment />
                  </button>
                  <ReactTooltip
                    id="attach-client"
                    place="bottom"
                    content="Attach Clients"
                  />
                </td>

                <td className="d-flex align-items-center justify-content-center">
                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(freelancer)}
                      disabled={
                        !hasPermission(user?.permissions, "update-freelancer")
                      }
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>

                  <span
                    className="px-2"
                    style={{
                      cursor: "pointer",
                      background: "red",
                      color: "white",
                      fontSize: "16px",
                      padding: "8px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <MdDelete
                      size={24}
                      data-tooltip-id="deleteTooltip"
                      data-tooltip-content="Delete"
                      onClick={() => handleDelete(freelancer.id)}
                      disabled={
                        !hasPermission(user?.permissions, "delete-freelancer")
                      }
                    />
                    <ReactTooltip id="deleteTooltip" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                No freelancers found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const handleChange = (value) => {
    if (value?.length < 1) {
      setSelectedOptions([]);
    }
    setSelectedOptions(value);
  };
  const showListing = () => {
    return (
      <>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {type?.data?.length > 0 ? (
              type?.data?.map((row, i) => (
                <tr key={row.id}>
                  <td>{i + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <h5> Select {type?.user} to Attach</h5>

        <MultiSelect
          options={options?.clients}
          value={selectedOptions}
          onChange={handleChange}
          labelledBy="Select"
          className="multi-select-container"
        />
      </>
    );
  };

  const getFormUI = () => {
    return type?.visible ? (
      showListing()
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formFreelancerName">
          <Form.Control
            type="text"
            placeholder="Enter freelancer name"
            value={newFreelancer.name}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, name: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerPhone">
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            value={newFreelancer.phone}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, phone: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerAddress">
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={newFreelancer.address}
            onChange={(e) =>
              setNewFreelancer({
                ...newFreelancer,
                address: e.target.value,
              })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={newFreelancer.email}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, email: e.target.value })
            }
          />
        </Form.Group>
        {!isEditing && (
          <Form.Group className="mb-3" controlId="formFreelancerPassword">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={newFreelancer.password}
              onChange={(e) =>
                setNewFreelancer({
                  ...newFreelancer,
                  password: e.target.value,
                })
              }
            />
          </Form.Group>
        )}

        {isEditing && (
          <Form.Group>
            <select
              name="status"
              className="rolelistingDropdown creatuserListing form-select"
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <option disabled value="">
                Select Status
              </option>
              {statusOptions.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Freelancer"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {
          setShow(true);
        }}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {type?.visible ? (
              <h2 className="create_user_heading">Attach Clients </h2>
            ) : (
              <>
                <h2 className="create_user_heading">{`${
                  isEditing ? "Update" : "Create"
                } Freelancer`}</h2>
                <p className=" fs-5 mt-3 text-muted">
                  {`${
                    isEditing ? "Update" : "Create"
                  } a Freelancer to manage and view your profile`}
                </p>
              </>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {type?.visible ? (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={handleAttachment}
                >
                  Attach Clients
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={
                    isEditing ? handleUpdateFreelancer : handleCreateFreelancer
                  }
                >
                  {`${isEditing ? "Update" : "Create"} Freelancer`}
                </Button>
              )}

              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default FreelancersListing;
